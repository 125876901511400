<template>
    <div class="container" style="position:relative">


        <h2 class="head2 mb-5">{{Lang.get('link3')}}</h2>
        <div class="text-red bold Truculenta text-32" style="max-width:550px;margin:auto;" v-html="Lang.get('text3')"></div>
        <div>


        </div>
        <div style="max-width:570px;margin:auto;">
            <div class="row">
                <div class="col-4 mb-4">
                    <div class="head1 mb-4 mt-5" v-html="Lang.get('revenue')"></div>
                    <img src="../assets/bc-hasilat.png" class="img-fluid" />
                </div>
                <div class="col-4 mb-4">
                    <div class="head1 mb-4 mt-5" v-html="Lang.get('OperatingProfit')"></div>
                    <img src="../assets/bc-esasfkari.png" class="img-fluid" />

                </div>
                <div class="col-4 mb-4">
                    <div class="head1 mb-4 mt-5" v-html="Lang.get('EBITDAMargin')"></div>
                    <img src="../assets/bc-favork.png" class="img-fluid" />
                </div>
                <div class="col-4 mb-4">
                    <div class="head1 mb-4 mt-5" v-html="Lang.get('ShareholdersEquity')"></div>
                    <img src="../assets/bc-ozkaynaklar.png" class="img-fluid" />
                </div>
                <div class="col-4 mb-4">
                    <div class="head1 mb-4 mt-5" v-html="Lang.get('GrossProfit')"></div>

                    <img src="../assets/bc-brutkar.png" class="img-fluid" />
                </div>
                <div class="col-4 mb-4">
                    <div class="head1 mb-4 mt-5" v-html="Lang.get('GrossProfitMargin')"></div>

                    <img src="../assets/bc-brutkarmarj.png" class="img-fluid" />
                </div>
            </div>
        </div>

        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>
        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Başlıca Finansal ve Operasyonel Göstergeler.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Key Financial and Operational Indicators.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
